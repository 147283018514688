import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, ConfigModule } from '@spartacus/core';
import { CmsPageGuard, PageLayoutComponent } from '@spartacus/storefront';
import { LegalGuard } from 'src/app/services/legal-announcement/legal-announcement.guard';
import { UserPermissionGuard } from 'src/app/services/user-permission.guard';
import { AccountSelectorGuard } from '../multi-account-selector/guards/account-selector.guard';
import { InvoiceListSearchComponent } from './components/invoice-list-search/invoice-list-search.component';
import { InvoicesListComponent } from './components/invoices-list/invoices-list.component';
import { InvoiceListingPageGuard } from './guards/invoice-listing-page.guard';
import { InvoicesResolver } from './resolver/invoices.resolver';
import { CustomerNotMigratedGuard } from 'src/app/shared/guards/customer-not-migrated.guard';

const routes: Routes = [
  {
    path: null,
    component: PageLayoutComponent,
    canActivate: [AuthGuard, AccountSelectorGuard, CustomerNotMigratedGuard, LegalGuard, CmsPageGuard, UserPermissionGuard],
    resolve: { invoices: InvoicesResolver },
    data: { cxRoute: 'invoices', pageLabel: 'VCInvoiceListPage' },
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    ConfigModule.withConfig({
      routing: {
        protected: true,
        routes: {
          invoices: {
            paths: ['invoices'],
          },
        },
      },
    }),
    ConfigModule.withConfig({
      cmsComponents: {
        MyInvoiceFlexComponent: {
          component: InvoicesListComponent,
          guards: [InvoiceListingPageGuard],
        },
        VCInvoiceSearchBoxFlexComponent: {
          component: InvoiceListSearchComponent,
        },
      },
    }),
  ],
  exports: [RouterModule],
})
export class InvoicesPageRoutingModule {}

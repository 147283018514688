import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService, SemanticPathService } from '@spartacus/core';
import { filter, map, Observable, of, switchMap, tap } from 'rxjs';
import { UpdateUserProfileService } from 'src/app/services/updateuserprofile/update-user-profile.service';

@Injectable({
  providedIn: 'root',
})

export class CustomerNotMigratedGuard implements CanActivate {
  constructor(private router: Router,
    private semanticPathService: SemanticPathService,
    protected authService: AuthService,
    private userProfileService: UpdateUserProfileService
  ) {}

  canActivate(): Observable<boolean> {
    return this.authService.isUserLoggedIn().pipe(
      switchMap((isLoggedIn) => {
        if (isLoggedIn) {
          return this.userProfileService.getUserDetails().pipe(
            filter((userDetails) => !!userDetails),
            map((userDetails: any) => {
              if (userDetails?.customerMigrated) {
                this.router.navigate(
                  this.semanticPathService.transform({
                    cxRoute: 'home',
                  })
                );
                return false;
              }
              return true;
            })
          );
        } else {
          return of(true);
        }
      })
    );
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, ConfigModule, NotAuthGuard, RoutingConfig } from '@spartacus/core';
import { CmsPageGuard, PageLayoutComponent } from '@spartacus/storefront';
// import { OrderDetailsPageComponent } from 'src/app/cms-pages/order-details/order-details-page.component';
import { PageType } from 'src/app/shared/model/common.mode';
import { ReturnFormConfirmationComponent } from './cms-components/return-form/return-form-confirmation/return-form-confirmation.component';
import { ReturnFormCreatePageComponent } from './cms-components/return-form/return-form-create-page/return-form-create-page.component';
import { AlconAccountDetailsPageComponent } from './cms-pages/alcon-account-details-page/alcon-account-details-page.component';
import { AlconFavoriteListComponent } from './cms-pages/alcon-favorite-list/alcon-favorite-list.component';
import { CartComponent } from './cms-pages/cart/cart.component';
import { CheckoutComponent } from './cms-pages/checkout/checkout.component';
import { ContactusPageComponent } from './cms-pages/contactus-page/contactus-page.component';
import { FranchiseSelectorPageComponent } from './cms-pages/franchise-selector-page/franchise-selector-page.component';
// import { DasboardPageComponent } from './cms-pages/dasboard-page/dasboard-page.component';
import { LegalAnnouncementComponent } from './cms-pages/legal-announcement/legal-announcement.component';
import { MyPatientComponent } from './cms-pages/my-patient/my-patient.component';
import { NewOrderComponent } from './cms-pages/new-order/new-order.component';
import { OrderConfirmationComponent } from './cms-pages/order-confirmation/order-confirmation.component';
import { PatientProfileComponent } from './cms-pages/patient-profile/patient-profile.component';
import { ProductDescriptionPageComponent } from './cms-pages/product-description-page/product-description-page.component';
import { ProductListPageComponent } from './cms-pages/product-list-page/product-list-page.component';
import { RegisterPageComponent } from './cms-pages/register-page/register-page.component';
import { RegisterationConfirmPageComponent } from './cms-pages/registeration-confirm-page/registeration-confirm-page.component';
import { SiteNotFoundComponent } from './cms-pages/site-not-found/site-not-found.component';
import { SurgicalRegisterPageComponent } from './cms-pages/surgical-register-page/surgical-register-page.component';
import { ToolsAndResourcesComponent } from './cms-pages/tools-resources/tools-resources.component';
import { CartItemsResolver } from './resolver/cart-items.resolver';
import { ContactUsResolver } from './resolver/contact-us.resolver';
import { EditScheduledOrdersResolver } from './resolver/edit-scheduled-orders-resolver.resolver';
import { FavouriteListResolver } from './resolver/favourite-list.resolver';
import { IndividualOrderResolver } from './resolver/individualorder/individual-order.resolver';
import { NewOrderResolver } from './resolver/new-order-resolver';
import { OrderDetailsResolver } from './resolver/order-details.resolver';
import { OrderSimulationResolver } from './resolver/order-simulation.resolver';
import { PatientOrderhistoryResolver } from './resolver/patient-orderhistory.resolver';
import { PatientProfileResolver } from './resolver/patient-profile.resolver';
import { PatientListResolver } from './resolver/patientsList.resolver';
import { LegalGuard } from './services/legal-announcement/legal-announcement.guard';
import { UserPermissionGuard } from './services/user-permission.guard';
import { AccountSelectorGuard } from './shared-modules/multi-account-selector/guards/account-selector.guard';
import { FranchiseSelectorGuard } from './shared/guards/franchise-selector.guard';
import { HasBaseSiteGuard } from './shared/guards/has-base-site.guard';
import { PdpAccessGuard } from './shared/guards/pdp-access.guard';
import { CustomerNotMigratedGuard } from './shared/guards/customer-not-migrated.guard';

const routes: Routes = [
  /* lazy load start */
  {
    path: null,
    data: {
      cxRoute: 'home',
      pageLabel: 'DashboardVCHomePage',
    },
    loadChildren: () => import('./shared-modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: null,
    data: {
      pageLabel: 'myProfilePage',
      cxRoute: 'myProfilePage',
    },
    loadChildren: () => import('./shared-modules/my-profile/my-profile.module').then((m) => m.MyProfileModule),
  },
  {
    path: null,
    data: { pageLabel: 'VCOrderListPage', Type: 'OrderList', cxRoute: 'orderHistory' },
    loadChildren: () => import('./shared-modules/order-history/order-history.module').then((m) => m.OrderHistoryModule),
  },
  {
    path: null,
    data: { pageLabel: 'VCOrderDetailsPage', Type: 'OrderDetails', cxRoute: 'orderDetailsPage' },
    loadChildren: () => import('./shared-modules/order-details/order-details.module').then((m) => m.OrderDetailsModule),
  },
  {
    path: null,
    data: {
      pageLabel: 'multi-account',
      cxRoute: 'accountSelector',
    },
    loadChildren: () =>
      import('./shared-modules/multi-account-selector/multi-account-selector.module').then(
        (m) => m.MultiAccountSelectorModule
      ),
  },

  // lazy loading end
  {
    path: null,
    component: RegisterPageComponent,
    canActivate: [HasBaseSiteGuard, NotAuthGuard, CmsPageGuard],
    data: {
      cxRoute: 'register',
      pageLabel: 'VcRegisterFormPage',
      showGlobalMessage: true,
    },
  },
  {
    path: null,
    component: SurgicalRegisterPageComponent,
    canActivate: [HasBaseSiteGuard, NotAuthGuard, CmsPageGuard],
    data: { pageLabel: 'registration', cxRoute: 'registration' },
  },
  {
    path: null,
    component: FranchiseSelectorPageComponent,
    canActivate: [HasBaseSiteGuard, NotAuthGuard, FranchiseSelectorGuard, CmsPageGuard],
    data: {
      cxRoute: 'franchise',
      pageLabel: 'franchiseselector',
    },
  },
  {
    path: null,
    component: RegisterationConfirmPageComponent,
    canActivate: [HasBaseSiteGuard, NotAuthGuard, CmsPageGuard],
    data: {
      cxRoute: 'registerSuccess',
      pageLabel: 'registrationsuccess',
    },
  },
  {
    path: null,
    component: AlconFavoriteListComponent,
    canActivate: [AuthGuard, AccountSelectorGuard, CustomerNotMigratedGuard, LegalGuard, CmsPageGuard, UserPermissionGuard],
    resolve: { favouriteList: FavouriteListResolver },
    data: { pageLabel: 'productFavouritesListingPage', cxRoute: 'favouritePage' },
  },
  {
    path: null,
    component: ProductListPageComponent,
    resolve: { IndividualorStockOrder: IndividualOrderResolver },
    data: { pageLabel: 'StockOrderPage', Type: 'StockOrder', cxRoute: 'StockOrder' },
    canActivate: [AuthGuard, AccountSelectorGuard, CustomerNotMigratedGuard, LegalGuard, CmsPageGuard, UserPermissionGuard],
  },
  {
    path: null,
    component: ProductListPageComponent,
    resolve: { IndividualorStockOrder: IndividualOrderResolver },
    data: { pageLabel: 'individualOrderPage', Type: 'IndividualOrder', cxRoute: 'IndividualOrder' },
    canActivate: [AuthGuard, AccountSelectorGuard, CustomerNotMigratedGuard, LegalGuard, CmsPageGuard, UserPermissionGuard],
  },
  {
    path: null,
    component: LegalAnnouncementComponent,
    canActivate: [AuthGuard, AccountSelectorGuard, CustomerNotMigratedGuard, CmsPageGuard, UserPermissionGuard],
    data: { pageLabel: 'VisioncareLegalAnnouncementConsentPage', cxRoute: 'legalAnnouncement' },
  },
  {
    path: null,
    component: CartComponent,
    resolve: { vcCart: CartItemsResolver },
    canActivate: [AuthGuard, AccountSelectorGuard, CustomerNotMigratedGuard, LegalGuard, CmsPageGuard, UserPermissionGuard],
    data: {
      cxRoute: 'cart',
      pageLabel: 'VCCartPage',
    },
  },
  {
    path: null,
    component: MyPatientComponent,
    canActivate: [AuthGuard, AccountSelectorGuard, CustomerNotMigratedGuard, LegalGuard, CmsPageGuard, UserPermissionGuard],
    resolve: { patients: PatientListResolver },
    data: { cxRoute: 'myPatients', pageLabel: 'VCMyPatientsPage', Type: 'MyPatients' },
  },
  {
    path: null,
    component: CheckoutComponent,
    canActivate: [AuthGuard, AccountSelectorGuard, CustomerNotMigratedGuard, LegalGuard, CmsPageGuard, UserPermissionGuard],
    resolve: { orderSimulate: OrderSimulationResolver },
    data: { pageLabel: 'VCCheckoutPage', Type: 'Checkout', cxRoute: 'checkout' },
  },
  {
    path: null,
    component: OrderConfirmationComponent,
    canActivate: [AuthGuard, AccountSelectorGuard, CustomerNotMigratedGuard, LegalGuard, CmsPageGuard, UserPermissionGuard],
    resolve: { vcCart: OrderDetailsResolver },
    data: { pageLabel: 'VCOrderConfirmationPage', Type: PageType.ORDER_CONFIRMATION, cxRoute: 'orderConfirmation' },
  },
  {
    path: null,
    component: PatientProfileComponent,
    canActivate: [AuthGuard, AccountSelectorGuard, CustomerNotMigratedGuard, LegalGuard, CmsPageGuard, UserPermissionGuard],
    resolve: { patientProfileInfo: PatientProfileResolver, patientOrderhistory: PatientOrderhistoryResolver },
    data: { pageLabel: 'VCPatientProfilePage', Type: 'PatientProfile', cxRoute: 'patientProfile' },
  },
  {
    path: null,
    component: ContactusPageComponent,
    canActivate: [AuthGuard, AccountSelectorGuard, CustomerNotMigratedGuard, LegalGuard, CmsPageGuard, UserPermissionGuard],
    resolve: { contactusInfo: ContactUsResolver },
    data: { pageLabel: 'contactUsPage', cxRoute: 'contactUsPage' },
  },
  {
    path: null,
    component: NewOrderComponent,
    resolve: { newOrderPatientInfo: NewOrderResolver },
    canActivate: [AuthGuard, AccountSelectorGuard, CustomerNotMigratedGuard, LegalGuard, CmsPageGuard, UserPermissionGuard],
    data: { pageLabel: 'VCAddNewOrderPage', Type: 'Neworder', cxRoute: 'newOrderPage' },
  },
  {
    path: null,
    component: NewOrderComponent,
    resolve: { newOrderPatientInfo: NewOrderResolver, editScheduledOrdersInfo: EditScheduledOrdersResolver },
    canActivate: [AuthGuard, AccountSelectorGuard, CustomerNotMigratedGuard, LegalGuard, CmsPageGuard, UserPermissionGuard],
    data: { pageLabel: 'VCAddNewOrderPage', Type: 'Neworder', cxRoute: 'editScheduledOrder' },
  },
  {
    path: null,
    component: ToolsAndResourcesComponent,
    canActivate: [AuthGuard, AccountSelectorGuard, CustomerNotMigratedGuard, LegalGuard, CmsPageGuard, UserPermissionGuard],
    data: { pageLabel: 'VCToolsResourcesPage', cxRoute: 'toolandResources' },
  },
  {
    path: null,
    component: AlconAccountDetailsPageComponent,
    canActivate: [AuthGuard, AccountSelectorGuard, CustomerNotMigratedGuard, LegalGuard, CmsPageGuard, UserPermissionGuard],
    data: {
      pageLabel: 'AccountDetailsPage',
      cxRoute: 'AccountDetailsPage',
    },
  },
  {
    path: null,
    component: ProductDescriptionPageComponent,
    data: { pageLabel: 'ProductPage', Type: 'product', cxRoute: 'product' },
    canActivate: [AuthGuard, UserPermissionGuard, AccountSelectorGuard, CustomerNotMigratedGuard, LegalGuard, CmsPageGuard],
    // Want sync behaviour PdpAccessGuard should run after UserPermissionGuard hence below
    children: [
      {
        path: '',
        canActivate: [PdpAccessGuard],
        component: ProductDescriptionPageComponent,
      },
    ],
  },
  {
    path: null,
    component: PageLayoutComponent,
    data: { pageLabel: 'returnFormPage', cxRoute: 'returnFormPage' },
    canActivate: [AuthGuard, AccountSelectorGuard, CustomerNotMigratedGuard, LegalGuard, CmsPageGuard, UserPermissionGuard],
    children: [
      {
        path: null,
        component: ReturnFormCreatePageComponent,
        data: { pageLabel: 'returnFormPage', cxRoute: 'returnFormCreatePage' },
      },
      {
        path: null,
        component: ReturnFormConfirmationComponent,
        data: { pageLabel: 'returnFormPage', cxRoute: 'returnFormConfirmationPage' },
      },
    ],
  },
  {
    path: 'site-directory',
    component: SiteNotFoundComponent,
  },
];

@NgModule({
  imports: [
    ConfigModule.withConfig({
      routing: {
        protected: true,
        routes: {
          IndividualOrder: {
            paths: ['individual-order/:catid', 'individual-order'],
            paramsMapping: { catid: 'code' },
          },
          StockOrder: {
            paths: ['stock-order/:catid', 'stock-order'],
            paramsMapping: { catid: 'code' },
          },
          orderConfirmation: {
            paths: ['order-confirmation/:orderid'],
            paramsMapping: { orderid: 'orderid' },
          },
          register: {
            paths: ['register'],
          },
          favouritePage: {
            paths: ['favoritelist'],
          },
          product: {
            paths: ['product/:productCode'],
            paramsMapping: { productCode: 'productCode' },
          },
          patientProfile: {
            paths: ['patientProfile/:profileId'],
            paramsMapping: { profileId: 'profileId' },
          },
          AccountDetailsPage: {
            paths: ['account-details'],
          },
          contactUsPage: {
            paths: ['contact'],
          },
          returnFormPage: {
            paths: ['return-form'],
          },
          returnFormCreatePage: {
            paths: ['create'],
          },
          returnFormConfirmationPage: {
            paths: ['confirmation/:email/:returnCode'],
            paramsMapping: { email: 'email', returnCode: 'returnCode' },
          },
          myPatients: {
            paths: ['myPatients'],
          },
          newOrderPage: {
            paths: ['new-order/:patientID'],
            paramsMapping: { patientID: 'patientID' },
          },
          toolandResources: {
            paths: ['tools'],
          },
          orderDetailsPage: {
            paths: ['order-details/:orderid'],
            paramsMapping: { orderid: 'orderid' },
          },
          accountSelector: {
            paths: ['account-selector'],
          },
          legalAnnouncement: {
            paths: ['legalannouncement'],
          },
          editScheduledOrder: {
            paths: ['edit-order-scheduled/:patientID/:code'],
            paramsMapping: { patientID: 'patientID', code: 'code' },
          },
          myProfilePage: {
            paths: ['my-profile'],
          },
          home: {
            paths: [''],
          },
          selectAccount: {
            paths: ['select-account'],
          },
          orderHistory: {
            paths: ['order-history'],
          },
          registration: {
            paths: ['registration'],
            protected: false,
          },
          franchise: {
            paths: ['franchiseselector'],
            protected: false,
          },
          registerSuccess: {
            paths: ['registrationsuccess'],
            protected: false,
          },
          userManagement: {
            paths: ['usermanagement'],
          },
          manageUser: {
            paths: ['manageuser'],
          },
          cart: {
            paths: ['cart'],
            protected: false,
          },
        },
      },
    } as RoutingConfig),
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

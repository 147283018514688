import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MigrationPopupComponent } from './migration-popup.component';
import { I18nModule } from '@spartacus/core';
import { PageSlotModule } from '@spartacus/storefront';



@NgModule({
  declarations: [MigrationPopupComponent],
  imports: [
    PageSlotModule
  ]
})
export class MigrationPopupModule { }

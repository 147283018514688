export function setWalkMeScript(WM_SCRIPT_URL) {
  const wm = document.createElement('script');
  wm.innerHTML = `(function() {
        var walkme = document.createElement('script');
        walkme.type = 'text/javascript';
        walkme.async = true;
        walkme.src = '${WM_SCRIPT_URL}';
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(walkme, s);
        window._walkmeConfig = {smartLoad:true};
      })()`;
  document.getElementsByTagName('head')[0].appendChild(wm);
}
